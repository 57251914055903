<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_plans_report')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_plans_report')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row">
                <div class="col-9">
                    <b-row>
                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('periods')">
                                <payment-periods-selectbox
                                    v-model="filter.period_id">
                                </payment-periods-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('products')">
                                <payment-products-selectbox
                                    v-model="filter.product_id">
                                </payment-products-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('payment_plan_types')">
                                <payment-plan-types-selectbox
                                    v-model="filter.type">
                                </payment-plan-types-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('registration_type')">
                                <parameter-selectbox
                                    code="registration_types"
                                    v-model="filter.registration_type">
                                </parameter-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox
                                    v-model="filter.faculty_code">
                                </faculty-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('program_level')">
                                <payment-program-level-selectbox
                                    v-model="filter.program_level">
                                </payment-program-level-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('program')">
                                <program-selectbox
                                    v-model="filter.program_code"
                                    :faculty_code="filter.faculty_code">
                                </program-selectbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('has_balance')">
                                <b-form-checkbox
                                    v-model="filter.has_balance" value="1">
                                </b-form-checkbox>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('has_payments')">
                                <b-form-checkbox
                                    v-model="filter.has_payments" value="1">
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </b-row>
                </div>
                <div class="col-3">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('student_numbers')">
                                    <lined-textarea v-model="filter.number"
                                                    :nowrap="false"
                                                    :disabled="false"
                                                    :styles="{ height: '15em', resize: 'both' }"
                                                    :validateError="errors[0]">
                                    </lined-textarea>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-12">
                    <processing-button @click="report" label="create_report"
                                       :processing="processing"></processing-button>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox";
import PaymentPlanTypesSelectbox from "@/components/interactive-fields/PaymentPlanTypesSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import PaymentProgramLevelSelectbox from "@/components/interactive-fields/PaymentProgramLevelSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import PaymentPlanService from "@/services/PaymentPlanService";
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import LinedTextarea from "@/components/elements/LinedTextarea";
import InvoiceService from "@/services/InvoiceService";
import qs from "qs";
import ProcessingButton from "@/components/elements/ProcessingButton.vue";

export default {
    components: {
        ProcessingButton,
        AppLayout,
        Header,
        HeaderMobile,
        ValidationProvider,
        ValidationObserver,
        PaymentPeriodsSelectbox,
        PaymentProductsSelectbox,
        PaymentPlanTypesSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        PaymentProgramLevelSelectbox,
        ParameterSelectbox,
        DatatableFilter,
        Datatable,
        LinedTextarea
    },
    data() {
        return {
            filter:{},
            processing:false
        }
    },
    metaInfo() {
        return {
            title: this.$t("payment_plans_report")
        }
    },
    methods: {
        async report() {
            this.processing = true;
            let data = {...this.filter};
            let oldValue=data.number;
            if(oldValue){
                data.number=data.number.split(/\n/);
            }
            const config = {
                params: {
                    filter: data
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };
            PaymentPlanService.report(config)
                .then(response => {
                    this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                }).catch(e => {
            }).finally(() => {
                this.processing = false;
                this.form.number = oldValue;
            });
        }
    }
}
</script>
